.App {
  text-align: center;
  
}
body {
  background-color: #282c34;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  background-color: #282c34;
  min-height: 100vh;
  /*display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  height: 400px; /* Adjust the height as needed */
}

.last-refresh-time {
  /*position: absolute;
  top: 0;
  right: 0;
  margin: 0.4em;*/
}

.last-refresh-time-mobile {
  /*position: absolute;
  top: 0;
  right: 0;
  margin: 0.4em;
  margin-right: 0.3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}

.login-button-container {
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 10;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.custom-dropdown-menu {
  z-index: 11 !important;
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.stickyColumn {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
